/* eslint-disable react/no-unknown-property */

import { Text, useAnimations, useGLTF } from "@react-three/drei";
import { applyProps, useFrame } from "@react-three/fiber";
import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";
import {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Group } from "three";
import { getGLBInfo } from "../../utils/threed";
import { GoalLights } from "./GoalLights";
import { MoonLight } from "./MoonLight";
import { GameState } from "./ReplayModel";

type ModelProps = {
  stadiumPath: string;
  gameState: MutableRefObject<GameState>;
};

export const Stadium = ({ stadiumPath, gameState }: ModelProps) => {
  const { nodes, animations } = useGLTF(stadiumPath);
  const stadiumRef = useRef<Group>(null);
  const scoreRef = useRef<Group>(null);
  const [score, setScore] = useState<string>("0 - 0");
  const { actions } = useAnimations(animations, stadiumRef);

  useEffect(() => {
    // Reset and fade in animation after an index has been changed
    Object.keys(actions).forEach((key) => {
      actions[key]?.play();
    });
  }, [actions]);

  useEffect(() => {
    setScore(
      `${gameState.current.score.teamA} - ${gameState.current.score.teamB}`
    );
  }, [scoreRef, gameState.current.score, gameState]);

  useEffect(() => {
    if (nodes && scoreRef.current) {
      scoreRef.current.position.copy(nodes.Scoreboard.position);
      scoreRef.current.position.setZ(scoreRef.current.position.z + 0.21);
    }
  }, [nodes, scoreRef, stadiumRef]);

  useLayoutEffect(() => {
    // enable the shadows, they might not be present in the blender export?
    nodes.Scene.traverse((obj) => {
      // HACKERDYHACK
      const o = obj as unknown as Instance;

      if (o.isMesh) {
        applyProps(o, {
          castShadow: true,
          receiveShadow: true,
          "material-envMapIntensity": 0.2,
        });
      }
    });
  }, [nodes.Scene]);
  useLayoutEffect(() => {
    const glb = getGLBInfo(nodes.Scene);
    console.log("🚀 ~ stadiumInfo", glb);
  }, [nodes.Scene]);

  return (
    <>
      <MoonLight />
      <GoalLights />

      {/* <Sphere>
        <meshPhongMaterial color="hotpink" />
      </Sphere> */}
      <primitive ref={stadiumRef} object={nodes.Scene} receiveShadow={true} />
      <Text
        ref={scoreRef}
        color={"#e3e3e3"}
        fontSize={0.5}
        maxWidth={200}
        lineHeight={1}
        letterSpacing={-0.1}
        textAlign={"center"}
        font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
        anchorX="center"
        anchorY="middle"
        characters="-0123456789!"
      >
        {score}
      </Text>
    </>
  );
};
